<template>
  <b-container class="pt-3">
    <h2>Profile</h2>
    <hr class="mt-1 mb-3" />

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>

        <b-card class="mb-3">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>

      <b-form @submit.prevent="updateInfo" v-if="info" class="mb-4">
        <h3>Your Info</h3>

        <b-form-group
          id="emailGroup"
          label="Email address"
          label-for="emailInput"
        >
          <b-form-input
            id="emailInput"
            type="email"
            autocorrect="off"
            autocapitalize="none"
            v-model.trim="info.email"
            required
            placeholder="email@domain"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="firstNameGroup"
          label="First name"
          label-for="firstNameInput"
        >
          <b-form-input
            id="firstNameInput"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            v-model.trim="info.first_name"
            required
            placeholder="last name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="lastNameGroup"
          label="Last name"
          label-for="lastNameInput"
        >
          <b-form-input
            id="lastNameInput"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            v-model.trim="info.last_name"
            required
            placeholder="first name"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" block
          >Update Info <b-spinner small v-if="updatingInfo"
        /></b-button>
      </b-form>

      <hr />

      <b-form @submit.prevent="changePassword">
        <h3>Password</h3>

        <b-form-group
          id="passwordGroup"
          label="Current password"
          label-for="passwordInput"
          invalid-feedback="Password doesn't meet minimum requirements"
        >
          <b-form-input
            id="passwordInput"
            v-model="passwordForm.current_password"
            :state="currentPasswordState"
            type="password"
            required
            placeholder="Enter current password"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="newPasswordGroup"
          label="New password"
          label-for="newPasswordInput"
          invalid-feedback="Password doesn't meet minimum requirements"
        >
          <b-form-input
            id="newPasswordInput"
            v-model="passwordForm.new_password_1"
            :state="newPasswordState"
            type="password"
            required
            placeholder="Enter new password"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="newPasswordGroup2"
          label="Repeat new password"
          label-for="repeatNewPasswordInput"
          invalid-feedback="Passwords don't match"
        >
          <b-form-input
            id="repeatNewPasswordInput"
            v-model="passwordForm.new_password_2"
            :state="passwordForm.new_password_1 == passwordForm.new_password_2"
            type="password"
            required
            placeholder="Enter new password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" block
          >Update Password <b-spinner small v-if="updatingPassword"
        /></b-button>
      </b-form>

      <hr />
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      info: null,
      passwordForm: {
        current_password: "",
        new_password_1: "",
        new_password_2: "",
      },
      updatingInfo: false,
      updatingPassword: false,
    };
  },
  computed: {
    currentPasswordState() {
      if (this.passwordForm.current_password.length > 0) {
        let regex = new RegExp("((?=.*\\d)(?=.*[A-Z])(?=.*\\W).{8,})");
        return regex.test(this.passwordForm.current_password);
      } else {
        return null;
      }
    },
    newPasswordState() {
      if (this.passwordForm.new_password_1.length > 0) {
        let regex = new RegExp("((?=.*\\d)(?=.*[A-Z])(?=.*\\W).{8,})");
        return regex.test(this.passwordForm.new_password_1);
      } else {
        return null;
      }
    },
  },
  methods: {
    async updateInfo() {
      this.updatingInfo = true;
      try {
        let response = await this.$http.patch(
          this.$store.state.apiURL + "/account",
          this.info,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
        this.$store.commit("setToken", {
          JWT: response.data.jwt,
        });
        this.$store.commit("setRefreshToken", {
          JWT: response.data.refresh_jwt,
        });
      } catch (error) {
        this.handleError(error);
      }
      this.updatingInfo = false;
    },
    async changePassword() {
      this.updatingPassword = true;
      try {
        await this.$http.put(
          this.$store.state.apiURL + "/account/password",
          this.passwordForm,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );
      } catch (error) {
        this.handleError(error);
      }
      this.updatingPassword = false;
    },
  },
  async created() {
    let response = await this.$http.get(this.$store.state.apiURL + "/account", {
      headers: {
        Authorization: "Bearer " + this.$store.state.JWT,
      },
    });
    this.info = response.data;
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 
</style>